import React, { Suspense, lazy } from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { OAuth } from 'skybase-oauth/oauth'
import { OAuthWrapper } from '@/oauth-wrapper'
import { setAppRoot } from 'skybase-ui/skybase-core/base/actions'
import { getAppRoot } from 'skybase-ui/skybase-core/utils/browser'
import { notificationsLocalStorageService } from 'skybase-ui/skybase-core/notifications/notifications-localstorage-service'
import { emitterLogger } from 'skybase-ui/skybase-core/emitter/emitter-logger'
import { emitToastOnErrorService } from 'skybase-ui/skybase-components/sb-toastr/services'
import { combineLocations } from 'skybase-ui/skybase-core/notifications/combine-locations'
import { SbErrorBoundary } from 'skybase-pages/sb-error-boundary'
import 'skybase-pages/sb-error-boundary/sb-error-page.scss'

import { CustomIntlProvider } from './containers/custom-intl-provider'
import { initializeLanguagesAndCulture } from './common/locales'
import { getMode } from './utils/get-mode'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import store from './stores/store'
import './styles/initial-loaders.scss' // preload fonts to correctly display loaders ASAP

const { REACT_APP_OAUTH_CONFIG_PATH } = process.env

const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(<SbLoader show size={loaderSize.L} />)

fetch(REACT_APP_OAUTH_CONFIG_PATH || '/oAuthConfig.json', { headers: { 'Cache-Control': 'must-revalidate' } })
  .then(
    response => response.json(),
    err => {
      console.error('Could not fetch config file: ', err)
    },
  )
  .then(
    config => {
      // initialize skybase-oauth services
      OAuth.setConfig(config)

      store.dispatch(setAppRoot(getAppRoot()))

      // initialize the storage for the notification service
      notificationsLocalStorageService(store)

      // initialize locations for redirect for notifications
      combineLocations({}) // Add custom locations to parameters here

      // initialize toast to error message service.
      emitToastOnErrorService(store.dispatch)

      if (getMode() !== 'production') {
        emitterLogger()
      }

      // Sets up languages and cultures.
      initializeLanguagesAndCulture(store.dispatch)

      const ProtectedContent = lazy(() => import(/* webpackChunkName: "oauth-protected" */ './oauth-protected-content'))
      const MainComponent = () => {
        return (
          <Provider store={store}>
            <CustomIntlProvider>
              <SbErrorBoundary>
                <OAuthWrapper renderFactory={!OAuth.isInLocalSDKMode}>
                  <Suspense fallback={<SbLoader show size={loaderSize.L} />}>
                    <ProtectedContent />
                  </Suspense>
                </OAuthWrapper>
              </SbErrorBoundary>
            </CustomIntlProvider>
          </Provider>
        )
      }

      root.render(<MainComponent />)
    },
    err => console.error('Could not parse config file: ', err),
  )
